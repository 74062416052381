import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { connect } from 'react-redux';
import axios from "axios";

const style = {
  flex: 1
};

class Pantalla2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      labels: [],
      pagina: 1,
      paginas: 1,
      porpagina: 10,
      buttonLabel: "Crear Excel",
      fileUrl: null
    };
    this.Pager = this.Pager.bind(this);
  }
  Pager() {
    let paginas = parseInt(this.state.paginas) - 1;
    let pagina = parseInt(this.state.pagina);
    let mostrar = 5;
    let margen = 2;
    var botones = [];
    if (paginas < mostrar) {
      mostrar = paginas;
    }
    if (paginas > 1) {

      if (pagina <= margen) {
        let pagtemp = pagina - margen;
        if (pagtemp < 1) {
          pagtemp = 1;
        }
        for (var i = pagtemp; i < pagtemp + mostrar && i <= paginas; i++) {

          botones.push(<button data-ira={i} onClick={(e) => this.ira(e)} className={(i == pagina) ? ('activo') : (undefined)} type="button" key={i}> {i} </button>);
        }
      } else {
        for (var i = pagina - margen; i < pagina + mostrar - margen && i <= paginas; i++) {

          botones.push(<button data-ira={i} onClick={(e) => this.ira(e)} className={(i == pagina) ? ('activo') : (undefined)} type="button" key={i}> {i} </button>);
        }
      }
      return (
        <div id="pager">
          <button data-ira='1' onClick={(e) => this.ira(e)} className={('1' == pagina) ? ('activo') : (undefined)} type="button" key="inicio"> |{'<'} </button>
          {botones}
          <button data-ira={paginas} onClick={(e) => this.ira(e)} className={(paginas == pagina) ? ('activo') : (undefined)} type="button" key='fin'> {'>'}| </button>
        </div>
      )
    } else {
      return '';
    }

  }
  ira(e) {
    let irapagina = e.target.dataset.ira;
    this.setState({ pagina: irapagina });
  }

  peticionAxios() {
    //traer meses
    let formData = new FormData();
    formData.append('pantalla', 2);
    formData.append('clienteid', this.props.loggedstate.vars.clienteid);
    formData.append('usuario', this.props.loggedstate.login.user);
    formData.append('permiso', this.props.loggedstate.login.permiso);
    formData.append('region', this.props.loggedstate.vars.region);
    formData.append('mes', this.props.loggedstate.vars.mes);
    formData.append('week', this.props.loggedstate.vars.week);
    formData.append('startdate', this.props.loggedstate.vars.startdate);
    formData.append('pagina', this.state.pagina);
    let generales
    let datos = [];
    let labels = [];
    axios({
      method: 'post',
      url: process.env.REACT_APP_URL + 'resultados_generales.php',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(response => response.data)
      .then((data) => {
        generales = data[0].pantalla2.resultados;

        generales.map(tienda => {

          datos.push(tienda);
          labels.push(tienda.name);
        })
        this.setState({ data: datos.reverse(), labels: labels.reverse(), paginas: data[0].pantalla2.paginas, porpagina: data[0].pantalla2.porpagina });

      })
      .catch(function (response) {
        //console.log(response[0]);
      });
  }
  getOption = () => {
    const options = {
      toolbox: {
        show: true,
        itemSize: 30,
        feature: {
          magicType: { type: ['line', 'bar'], iconStyle: { borderWidth: 3 } },
          restore: { iconStyle: { borderWidth: 3 } },
          saveAsImage: { iconStyle: { borderWidth: 3 } }
        }
      },
      visualMap: {
        orient: 'horizontal',
        left: 'center',
        top: 40,
        min: 0,
        max: 100,
        text: ['Excelente', 'Deficiente'],
        // Map the score column to color
        dimension: 0,
        inRange: {
          color: ['#FD665F', '#FFCE34', '#65B581']
        }
      },
      grid: {
        left: 10,
        right: 15,
        bottom: 5,
        top: 80,
        containLabel: true
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b} {c}' + '%'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      yAxis: {
        type: 'category',
        data: this.state.labels,
        axisLabel: {
          formatter: function (params) {
            var newParamsName = "";
            newParamsName = params.replace(/(\S+\s*){1,4}/g, "$&\n");
            return newParamsName
          },
          fontWeight: 'bold',
          fontSize: 14,
        }
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          fontWeight: 'bold',
          fontSize: 14,
        }
      },
      series: [
        {
          data: this.state.data,
          type: 'bar',
          label: {
            show: true,
            position: 'inside',
            color: "#333",
            fontSize: 22,
            fontWeight: 'bold',
            formatter: '{c}' + '%'
          },
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }
      ]
    }
    return options;
  }
  componentDidMount() {
    this.peticionAxios();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.pagina !== this.state.pagina) {
      this.peticionAxios();
    }
    if (prevProps.loggedstate.vars.mes !== this.props.loggedstate.vars.mes || prevProps.loggedstate.vars.region !== this.props.loggedstate.vars.region || prevProps.loggedstate.vars.week !== this.props.loggedstate.vars.week) {
      if (this.state.pagina == 1) {
        this.peticionAxios();
      } else {
        this.setState({ pagina: 1 });
      }

    }
  }

  downloadExcel = async () => {
    this.setState({ buttonLabel: "Procesando" });
    // Prepare the URL
    const url = process.env.REACT_APP_URL + 'resultados_generales.php?excel=true';

    // Prepare the data to be sent as POST
    let formData = new FormData();
    formData.append('pantalla', 2); // Set the pantalla value
    formData.append('clienteid', this.props.loggedstate.vars.clienteid); // Fetch clienteid from the correct location
    formData.append('usuario', this.props.loggedstate.login.user);
    formData.append('permiso', this.props.loggedstate.login.permiso);
    formData.append('region', this.props.loggedstate.vars.region);
    formData.append('mes', this.props.loggedstate.vars.mes);

    // Send the request
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    // Get the file URL from the response
    const fileUrl = process.env.REACT_APP_URL + response.data.url;

    this.setState({ buttonLabel: "Descargar Excel", fileUrl });
  }

  render() {
    return (
      <div id="pantalla2" className="contenedorgrafica">
        <ReactEcharts
          option={this.getOption()}
          className="grafica"
          style={style}
          notMerge={false}
          lazyUpdate={true}
        />
        <this.Pager />
        
                    <a 
                        href={this.state.fileUrl} 
                        onClick={this.state.fileUrl ? null : this.downloadExcel}
                        style={{
                            display: 'inline-block',
                            padding: '10px 20px',
                            margin: '20px auto',
                            background: '#007BFF',
                            color: '#fff',
                            textDecoration: 'none',
                            textAlign: 'center',
                            borderRadius: '4px',
                        }}
                    >
                        {this.state.buttonLabel}
                    </a>
          
      </div>
    );
  }
}
const mapStateToProps = state => ({
  loggedstate: state
})
export default connect(mapStateToProps,)(Pantalla2);