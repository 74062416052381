import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';

class Cruces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cliente: props.loggedstate.vars.slug,
            modulos: props.loggedstate.vars.modulos
        }
    }
    cerrar(e) {
        //e.currentTarget.parentNode.classList.remove("activo");
        e.currentTarget.closest(".panel").classList.remove("activo");
    }
    render() {
        return (
            <ul>
                {console.log('modulos', this.state.modulos)}
                <li>
                    <NavLink to={this.state.cliente + "/"} onClick={this.cerrar}>
                        <span>Indicadores Generales</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={this.state.cliente + "/1"} onClick={this.cerrar}>

                        <span>Calificaciones Generales y por Área</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={this.state.cliente + "/2"} onClick={this.cerrar}>

                        <span>Ranking de Calificaciones por tienda</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={this.state.cliente + "/3"} onClick={this.cerrar}>

                        <span>Calificación por Área</span>
                    </NavLink>
                </li>
                {/*<li>
                <NavLink  to={this.state.cliente+ "/10"} onClick={this.cerrar}>
                    
                    <span>Histórico de Calificaciones por Área</span>
                </NavLink>
            </li>
            <li>
                <NavLink  to={this.state.cliente+ "/4"} onClick={this.cerrar}>
                    
                    <span>Calificación por tiendas</span>
                </NavLink>
        </li>*/}
                <li>
                    <NavLink to={this.state.cliente + "/5"} onClick={this.cerrar}>

                        <span>Cuestionario e Imágenes de la tienda</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={this.state.cliente + "/6"} onClick={this.cerrar}>

                        <span>Tendencia de Calificaciones Generales</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={this.state.cliente + "/7"} onClick={this.cerrar}>

                        <span>Calificación por pregunta</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={this.state.cliente + "/8"} onClick={this.cerrar}>

                        <span>Histórico de preguntas a lo largo del tiempo</span>
                    </NavLink>
                </li>
                {/*<this.state.modulos.includes(16) || this.state.modulos.includes('16') ? (
                li>
                    <NavLink to={this.state.cliente + "/16"} onClick={this.cerrar}>

                        <span>Experiencia de la visita</span>
                    </NavLink>
                </li>
                ) : null*/}
                {this.state.modulos.includes(17) || this.state.modulos.includes('17') ? (
                    <li>
                        <NavLink to={this.state.cliente + "/17"} onClick={this.cerrar}>

                            <span>Calificación del usuario de Google</span>
                        </NavLink>
                    </li>
                ) : null}
                {this.state.modulos.includes(17) || this.state.modulos.includes('17') ? (
                    <li>
                        <NavLink to={this.state.cliente + "/17b"} onClick={this.cerrar}>
                            <span>Histórico de calificaciones del usuario de Google</span>
                        </NavLink>
                    </li>
                ) : null}
                {this.state.cliente == 'shasa' ? (
                <li>
                    <NavLink to={this.state.cliente + "/reportes"} onClick={this.cerrar}>
                        <span>Reportes</span>
                    </NavLink>
                </li>):(null)}
                {console.log('State ', this.state)}
            </ul>
        );
    }
}
const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps)(Cruces);