import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Slider from '@mui/material/Slider';

const marks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 100,
        label: '100%',
    },
];

class Pantalla7 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            changes: [0, 100],
            slider: [0, 100]
        };
        this.Construir = this.Construir.bind(this);
    }

    peticionAxios() {
        let formData = new FormData();
        formData.append('pantalla', 7);
        formData.append('clienteid', this.props.loggedstate.vars.clienteid);
        formData.append('usuario', this.props.loggedstate.login.user);
        formData.append('permiso', this.props.loggedstate.login.permiso);
        formData.append('region', this.props.loggedstate.vars.region);
        formData.append('mes', this.props.loggedstate.vars.mes);
        formData.append('week', this.props.loggedstate.vars.week);
        formData.append('startdate', this.props.loggedstate.vars.startdate);
        formData.append('minimo', this.state.slider[0]);
        formData.append('maximo', this.state.slider[1]);
        axios({
            method: 'post',
            url: process.env.REACT_APP_URL + '7.php',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
            .then(response => response.data)
            .then((data) => {
                this.setState({ data: data });
                //console.log(generales);
            })
            .catch(function (response) {
                //console.log(response[0]);
            });
    }
    componentDidMount() {
        this.peticionAxios();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loggedstate.vars.mes !== this.props.loggedstate.vars.mes || prevProps.loggedstate.vars.region !== this.props.loggedstate.vars.region || prevState.slider !== this.state.slider  || prevProps.loggedstate.vars.week !== this.props.loggedstate.vars.week) {
            this.peticionAxios();
        }
    }
    Construir(prevState) {
        const getComponents = (color) => Array.from({ length: 3 }, (_, i) => Math.floor(color / 16 ** (2 * i) % 16 ** 2))

        // interpolate arrays by component
        const interpolate = (arr1, arr2, percent) => arr1.map((v, index) => Math.floor(v + (arr2[index] - v) * (percent / 100)))

        function colors(value) {
            const StartColor = 0xFD665F; // Red
            const MiddleColor = 0xFFCE34; // Yellow
            const EndColor = 0x65B581; // Green

            let [start, end, v] = value < 50
                ? [StartColor, MiddleColor, value * 2]
                : [MiddleColor, EndColor, (value - 50) * 2]

            let interpoled = interpolate(getComponents(start), getComponents(end), v)

            return interpoled.reduce((n, component, index) => n + component * (16 ** (index * 2)), 0).toString(16).padStart(6, '0')
        }
        //preparar datos

        if (this.state.data !== prevState.data) {
            let tablas = [];
            this.state.data.map(region => {
                Object.keys(region).map(function (key, index) {

                    tablas.push(
                        <Tr key={'tit'+index} className="campo_tit2">
                            <Th colSpan="2">{key}</Th>
                        </Tr>
                    )
                    let content = region[key];
                    Object.keys(content).map(function (key, index) {
                        tablas.push(<Tr><Td>{key}</Td><Td style={{ backgroundColor: "#" + colors(content[key].total) }}>{content[key].total}%</Td></Tr>);
                    })
                })
            })
            return (tablas);
        }
    }
    handleChange = (event, value) => this.setState({ changes: value });
    handleRange = (event, value) => this.setState({ slider: value });
    render() {
        return (
            <div id="pantalla7" className="contenedorgrafica">
                <div id='rango'>
                    <Slider
                        value={this.state.changes}
                        onChange={this.handleChange}
                        onChangeCommitted={this.handleRange}
                        step={10}
                        marks={marks}
                    />
                </div>
                <div id="cont_interno">
                    <Table className="tabla_resultados">
                        <Tbody>
                            {<this.Construir />}
                        </Tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loggedstate: state
})
export default connect(mapStateToProps)(Pantalla7);